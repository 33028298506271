import React, { useState, useLayoutEffect, useContext } from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { Global, css } from '@emotion/react';
import { ThemeContext } from './theme-provider';
import NewsItem from '../components/news-item';
import Logo from '../images/svg/logo.svg'
import LogoSmall from '../images/svg/logo--small.svg'
import Menu from '../images/svg/menu.svg'
import Close from '../images/svg/close.svg'
import ArrowRight from '../images/svg/arrow-right.svg';

export default function Header() {
  const [news, setNews] = useState([]);
  const [newsTags, setNewsTags] = useState([]);
  const [activeNewsTags, setActiveNewsTags] = useState([]);
  const [activeNewsTagIndex, setActiveNewsTagIndex] = useState(null);
  const [showMenu, setShowMenu] = useState(false);

  const theme = useContext(ThemeContext);

  const data = useStaticQuery(graphql`
    query {
      news: allSanityNews(limit: 5, sort: {fields: date, order: DESC}) {
        nodes {
          id
          date
          title
          _rawText
          image {
            asset {
              gatsbyImageData(width: 600, formats: WEBP)
            }
          }
          tags {
            id
            title
          }
        }
      }
      newsTags: allSanityNewsTag(sort: {fields: title, order: ASC}) {
        nodes {
          id
          title
        }
      }
    }
  `);

  const newsBackgroundColors = [
    'var(--green-1)',
    'var(--blue-1)',
    'var(--yellow-1)',
    'var(--green-2)',
    'var(--blue-2)',
    'var(--yellow-2)',
  ];

  useLayoutEffect(() => {
    setNews(data.news.nodes);
  }, [data.news.nodes]);

  useLayoutEffect(() => {
    setNewsTags(data.newsTags.nodes);
  }, [data.newsTags.nodes]);

  useLayoutEffect(() => {
    let newNews = data.news.nodes;

    if (activeNewsTags.length > 0) {
      newNews = newNews.filter((news) => {
        return news.tags.some((tag) => activeNewsTags.includes(tag.id));
      });
    }

    setNews(newNews);
  }, [activeNewsTags]);

  function addActiveNewsTag(id) {
    const newActiveNewsTags = []; // Set to [...activeNewsTags] to make addititive
    const index = activeNewsTags.indexOf(id);

    if (index !== -1) {
      newActiveNewsTags.splice(index, 1);
    } else {
      newActiveNewsTags.push(id);
    }

    setActiveNewsTags(newActiveNewsTags);
  }


  return (
    <div>
      <header
        css={css`
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          display: grid;
          grid-template-columns: repeat(12, 1fr);
          gap: var(--gutter);
          padding: 0 var(--margin);
          margin-bottom: 4rem;
          z-index: 10;
          text-shadow: 0px 0px 25px rgba(${(theme.menuColorDark) ? '255, 255, 255, 0.2' : '0, 0, 0, 0.2'});
        `}
      >
        <Global
          styles={css`
            body {
              overflow: ${(theme.showNews) ? 'hidden' : 'visible'};
            }
          `}
        />
        <div
          css={css`
            grid-column: span 7;
            margin-top: 4rem;

            @media (max-width: 1280px) {
              grid-column: span 6;
            }
          `}
        >
          <Link
            to="/"
            state={{random: true}}
            css={css`
              svg {
                filter: drop-shadow(0px 0px 20px rgba(${(theme.logoColorDark) ? '255, 255, 255' : '0, 0, 0'}, 0.1));

                @media (max-width: 900px) {
                  width: 10rem;
                  height: auto;
                }

                path {
                  fill: ${(theme.logoColorDark) ? 'var(--black)' : 'var(--white)'};
                  transition: fill 0.6s var(--curve);
                }
              }
            `}
          >
            <Logo />
          </Link>
        </div>
        <div
          css={css`
            display: none;
            position: fixed;
            top: 0;
            right: 0;
            padding: 58px 56px;
            cursor: pointer;

            @media (max-width: 1024px) {
              display: block;
            }

            @media (max-width: 900px) {
              padding: 38px 31px;
            }

            svg path {
              fill: ${(theme.logoColorDark || theme.postIntro) ? 'var(--black)' : 'var(--white)'};
              transition: fill 0.6s var(--curve);
            }
          `}
          onClick={() => setShowMenu(true)}
        >
          <Menu />
        </div>
        <nav
          className="type--extra-small"
          css={css`
            grid-column: span 5;
            font-weight: bold;
            text-transform: uppercase;
            margin-top: 3rem;

            @media (max-width: 1280px) {
              grid-column: span 6;
            }

            @media (max-width: 1024px) {
              display: none;
            }

            ul {
              display: flex;
              justify-content: space-between;
              padding: 0;

              &:hover {
                li {
                  opacity: 0.15;
                }
              }

              li {
                list-style: none;
                transition: opacity 0.6s var(--curve);

                &:hover {
                  opacity: 1;
                  transition: opacity 0s;
                }

                &:last-of-type {
                  margin-right: 0;
                }
              }
            }

            a {
              color: ${(theme.menuColorDark) ? 'var(--black)' : 'var(--white)'};
              text-decoration: none;
              transition: color 0.6s var(--curve);
            }
          `}
        >
          <ul>
            <li>
              <Link to="/approach/">Approach</Link>
            </li>
            <li>
              <Link to="/people/">People</Link>
            </li>
            <li>
              <Link to="/projects/">Projects</Link>
            </li>
            <li>
              <a
                role="button"
                aria-expanded={(theme.showNews) ? true : false}
                tabIndex="0"
                onClick={() => theme.setShowNews(!theme.showNews)}
                onKeyPress={(e) => {
                  e.persist();

                  if (e.key === 'Enter')
                    theme.setShowNews(!theme.showNews);
                }}
              >News</a>
            </li>
            <li>
              <Link to="/impact/">Impact</Link>
            </li>
            <li>
              <Link to="/contact/">Contact</Link>
            </li>
          </ul>
        </nav>
      </header>
      <header
        css={css`
          display: grid;
          grid-template-columns: repeat(12, 1fr);
          gap: var(--gutter);
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          background-color: var(--white);
          padding: 1.5rem var(--margin);
          box-shadow: 0 0 1.5rem rgba(1, 1, 1, 0.1);
          z-index: 10;
          transform: ${(theme.showMenu) ? 'translateY(0)' : 'translateY(calc(-100% - 1.5rem))'};
          transition: transform 0.6s var(--curve);

          @media (max-width: 1024px) {
            display: none;
          }
        `}
      >
        <div
          css={css`
            grid-column: span 7;
            line-height: 0;
          `}
        >
          <Link
            to="/"
            state={{random: true}}
            css={css`
              svg path {
                fill: var(--black);
                transition: fill 0.6s var(--curve);
              }
            `}
            onClick={() => theme.setShowMenu(false)}
          >
            <LogoSmall />
          </Link>
        </div>
        <nav
          className="type--extra-small"
          css={css`
            grid-column: span 5;
            font-weight: bold;
            text-transform: uppercase;

            ul {
              display: flex;
              justify-content: space-between;
              position: relative;
              top: 0.8rem;
              padding: 0;
              margin: 0;

              &:hover {
                li {
                  opacity: 0.15;
                }
              }

              li {
                list-style: none;
                transition: opacity 0.6s var(--curve);

                &:hover {
                  opacity: 1;
                  transition: opacity 0s;
                }

                &:last-of-type {
                  margin-right: 0;
                }
              }
            }

            a {
              color: ;
              color: var(--black);
              text-decoration: none;
              transition: color 0.6s var(--curve);
            }
          `}
        >
          <ul>
            <li
              onClick={() => theme.setShowMenu(false)}
            >
              <Link to="/approach/">Approach</Link>
            </li>
            <li
              onClick={() => theme.setShowMenu(false)}
            >
              <Link to="/people/">People</Link>
            </li>
            <li
              onClick={() => theme.setShowMenu(false)}
            >
              <Link to="/projects/">Projects</Link>
            </li>
            <li
              onClick={() => theme.setShowMenu(false)}
            >
              <a
                role="button"
                aria-expanded={(theme.showNews) ? true : false}
                tabIndex="0"
                onClick={() => theme.setShowNews(!theme.showNews)}
                onKeyPress={(e) => {
                  e.persist();

                  if (e.key === 'Enter')
                    theme.setShowNews(!theme.showNews);
                }}
              >News</a>
            </li>
            <li
              onClick={() => theme.setShowMenu(false)}
            >
              <Link to="/impact/">Impact</Link>
            </li>
            <li
              onClick={() => theme.setShowMenu(false)}
            >
              <Link to="/contact/">Contact</Link>
            </li>
          </ul>
        </nav>
      </header>
      <div
        css={css`
          position: fixed;
          top: 0;
          right: 0;
          bottom: 0;
          left: 50%;
          background-color: ${(activeNewsTags.length > 0) ? newsBackgroundColors[activeNewsTagIndex] : 'var(--beige)' };
          overflow-y: scroll;
          transform: translateX(${(theme.showNews) ? '0%' : '100%'});
          transition: transform 0.6s var(--curve), background-color 0.6s var(--curve);
          z-index: 999;

          @media (max-width: 1024px) {
            left: 0;
          }
        `}
      >
        <div
          className="type--extra-small"
          css={css`
            position: sticky;
            top: 0;
            font-family: 'Maax Medium', sans-serif;
            background-color: inherit;
            padding: 5rem var(--margin) 3rem;
            border-bottom: 1px solid var(--black);
            margin-bottom: 4rem;
            z-index: 1;

            > div {
              display: inline-block;
              margin-right: 4rem;
              margin-bottom: 2rem;

              &::before {
                content: '';
                display: inline-block;
                height: 1rem;
                width: 1rem;
                border: 1px solid var(--black);
                border-radius: 50%;
                margin-right: 1rem;
              }

              &[role="button"] {
                cursor: pointer;
              }

              &[aria-pressed="true"], &:hover {
                &::before {
                  background-color: var(--black);
                }
              }
            }
          `}
        >
          <div
            onMouseDown={() => {setActiveNewsTags([]); setActiveNewsTagIndex(null);}}
            onKeyPress={(e) => {if (e.key === 'Enter') { setActiveNewsTags([]); setActiveNewsTagIndex(null);}}} 
            tabIndex="0"
            role="button"
            aria-pressed={activeNewsTags.length === 0}
          >
            All News
          </div>
          {newsTags.length > 0 && newsTags
            .map((tag, i) => (
              <div
                key={tag.id}
                onMouseDown={() => {addActiveNewsTag(tag.id); setActiveNewsTagIndex(i);}}
                onKeyPress={(e) => {if (e.key === 'Enter') { addActiveNewsTag(tag.id); setActiveNewsTagIndex(i);}}} 
                tabIndex="0"
                role="button"
                aria-pressed={activeNewsTags.includes(tag.id)}
              >
                {tag.title}
              </div>
            ))
          }
          <img
            css={css`
              position: absolute;
              top: 4.5rem;
              right: var(--margin);
              cursor: pointer;
            `}
            src="/images/cross.svg"
            alt="Cross"
            role="button"
            tabIndex="0"
            onClick={() => theme.setShowNews(false)}
            onKeyPress={(e) => {
              e.persist();

              if (e.key === 'Enter')
                theme.setShowNews(false);
            }}
          />
        </div>
        <div
          css={css`
            padding: 2rem var(--margin);
          `}
        >
          {news.length > 0 && news
            .map((item) => (
              <NewsItem key={item.id} news={item} showImage={true} />
            ))
          }
        </div>
      </div>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          position: fixed;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background-color: var(--black);
          padding: 0 var(--margin);
          z-index: 999;
          opacity: ${(showMenu) ? 1 : 0};
          pointer-events: ${(showMenu) ? 'auto' : 'none'};
          transition: opacity 1.2s var(--curve);
        `}
      >
        <div
          css={css`
            margin-top: 4rem;
          `}
        >
          <Link
            to="/"
            state={{random: true}}
            css={css`
              svg {
                @media (max-width: 900px) {
                  width: 10rem;
                  height: auto;
                }

                path {
                  fill: var(--white);
                }
              }
            `}
          >
            <Logo />
          </Link>
        </div>
        <div
          css={css`
            position: fixed;
            top: 0;
            right: 0;
            padding: 50px 50px;
            cursor: pointer;

            @media (max-width: 900px) {
              padding: 30px 25px;
            }

            svg path {
              fill: var(--white);
            }
          `}
          onClick={() => setShowMenu(false)}
        >
          <Close />
        </div>
        <div
          css={css`
            flex-grow: 1;
          `}
        >
          <nav
            className="type--heading-two"
            css={css`
              display: flex;
              flex-direction: column;
              height: 100%;
              font-weight: bold;
              text-transform: uppercase;
              padding-top: calc(var(--margin) * 2);
              padding-bottom: calc(var(--margin) * 3);

              @media (max-width: 900px) {
                font-size: 24px;
                padding-top: calc(var(--margin) * 2);
                padding-bottom: var(--margin);
              }

              a {
                display: flex;
                align-items: center;
                height: 20%;
                color: var(--white);
                text-decoration: none;
                border-top: 1px solid var(--white);

                &:last-of-type {
                  border-bottom: 1px solid var(--white);
                }

                span {
                  position: relative;
                  top: 0.4rem;
                }

                svg {
                  width: 25px;
                  height: auto;
                  margin-left: auto;

                  @media (max-width: 900px) {
                    width: 15px;
                  }

                  path {
                    fill: var(--white);
                  }
                }
              }
            `}
            onClick={() => setShowMenu(false)}
          >
              <Link to="/approach/">
                <span>Approach</span> <ArrowRight />
              </Link>
              <Link to="/people/">
                <span>People</span> <ArrowRight />
              </Link>
              <Link to="/projects/">
                <span>Projects</span> <ArrowRight />
              </Link>
              <a
                role="button"
                aria-expanded={(theme.showNews) ? true : false}
                tabIndex="0"
                onClick={() => theme.setShowNews(!theme.showNews)}
                onKeyPress={(e) => {
                  e.persist();

                  if (e.key === 'Enter')
                    theme.setShowNews(!theme.showNews);
                }}
              >
                <span>News</span> <ArrowRight />
              </a>
              <Link to="/impact/">
                <span>Impact</span> <ArrowRight />
              </Link>
              <Link to="/contact/">
                <span>Contact</span> <ArrowRight />
              </Link>
          </nav>
        </div>
      </div>
    </div>
  )
}
